@tailwind base;
@layer base {
    html {
        @apply text-[#4f4f4f];
    }
    html.dark {
        @apply text-neutral-50;
        @apply bg-neutral-800;
    }
}

@tailwind components;
@tailwind utilities;

/* Custom colors */
.color-gray-light {
    background: #edf2f7;
}

.color-orange-light {
    background: #fce7cc;
}

.color-blue-blossom {
    background: #3468c0;
}

.color-blue-dark {
    background: #4d4c7d;
}

/* Custom utility class for buttons */
.button-orange {
    @apply rounded-full py-2 px-6 mr-3 tracking-wide bg-orange-600 inline-block border border-orange-600 text-lg lg:text-xl font-semibold text-white hover:text-orange-600 hover:bg-white focus:outline-none focus:ring focus:ring-orange-400 focus:ring-opacity-50 transition-colors duration-200 transform cursor-pointer;
}

.button-white-outline {
    @apply lg:inline-block lg:mr-3 lg:w-max py-2 px-6 tracking-wide cursor-pointer bg-white border border-gray-900 text-lg lg:text-xl text-gray-900 font-semibold hover:bg-gray-900 hover:text-white focus:outline-none focus:ring focus:ring-gray-400 focus:ring-opacity-50 rounded-full transition transform duration-200;
}

.social-media-icon {
    @apply mx-1.5 text-slate-200 transition-colors duration-200 transform hover:text-orange-600;
}

.service-card {
    @apply bg-white p-4 rounded-xl w-full h-full flex flex-col items-center;
}

.service-card-image {
    @apply inline-block p-3 text-orange-500 rounded-xl dark:text-white dark:bg-orange-500;
}

.service-card-title {
    @apply text-xl font-semibold text-gray-700 capitalize dark:text-white mt-4 text-center;
}

.contact-form-item {
    @apply w-full mt-2 px-3 py-2 text-white placeholder:text-slate-300 bg-transparent outline-none border focus:border-orange-300 shadow-sm rounded-lg;
}

.paragraph-heading {
    @apply text-xl font-bold py-4;
}

.paragraph p {
    @apply py-2;
}
